'use client';

import { useEffect, useState } from 'react';
import { useUser } from '@clerk/nextjs';
import { useRouter } from 'next/navigation';

const INITIALIZATION_TIMEOUT = 10000; // 10 seconds

const generateRandomLetters = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

// async component
const InitializeSettings = ({ children }) => {
  const { isLoaded, isSignedIn, user } = useUser();
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState(null);
  const router = useRouter();

  useEffect(() => {
    const initializeSettings = async () => {
      if (!isLoaded || !isSignedIn || !user) {
        console.log('User not loaded or not signed in');
        setIsInitialized(true);
        return;
      }

      console.log('Starting initialization process');

      const timeoutId = setTimeout(() => {
        console.log('Initialization timed out');
        setError(
          'Initialization timed out. Please refresh the page and try again.'
        );
        setIsInitialized(true);
      }, INITIALIZATION_TIMEOUT);

      try {
        console.log('Fetching private metadata');
        const response = await fetch(
          `/api/get-private-metadata?userId=${user.id}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log('Received data:', data);
          let settings = data.clientSettings || {};

          if (!settings.organizationId || !settings.frontendCollectionName) {
            console.log('Generating new settings');
            // the orgId is a the first 8 digits / letters of the userId after "user_"
            const orgId = settings.organizationId || user.id.slice(5, 13);
            const client_signup_date_stamp = Math.floor(Date.now() / 1000);
            const random_3_letters = generateRandomLetters(3);
            const newCollectionName = `${random_3_letters}_${client_signup_date_stamp}_${orgId}`;

            settings = {
              ...settings,
              organizationId: orgId,
              frontendCollectionName: newCollectionName,
            };

            console.log('Saving new settings');
            const saveResponse = await fetch('/api/set-private-metadata', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                userId: user.id,
                clientSettings: settings,
              }),
            });

            if (!saveResponse.ok) {
              throw new Error('Failed to save new settings');
            }

            console.log('New settings saved:', settings);
          } else {
            console.log('Existing settings found:', settings);
          }
        } else {
          throw new Error('Failed to fetch client settings');
        }
      } catch (error) {
        console.error('Error initializing settings:', error);
        setError('Failed to initialize settings. Please try again later.');
      } finally {
        clearTimeout(timeoutId);
        console.log('Initialization process completed');
        setIsInitialized(true);
      }
    };

    initializeSettings();
  }, [isLoaded, isSignedIn, user, router]);

  if (!isInitialized) {
    return (
      <div className='flex flex-col justify-center items-center h-screen bg-gray-100'>
        <div className='loader animate-spin h-12 w-12 border-4 border-t-4 border-green-600 rounded-full mb-4'></div>
        <p className='text-xl text-green-800 font-semibold'>
          Initializing application... Please wait.
        </p>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  console.log('Rendering children');
  return <>{children}</>;
};

export default InitializeSettings;
