'use client';
import { useState, useEffect } from 'react';
import Link from 'next/link';
import './globals.css';
import {
  ClerkProvider,
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
} from '@clerk/nextjs';
import Image from 'next/image';
import { Menu, X } from 'lucide-react';
import InitializeSettings from '../components/InitializeSettings';

function NavBar() {
  const { isLoaded, isSignedIn, user } = useUser();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      console.log('User Info from the useEffect:', user);
    }
  }, [isLoaded, isSignedIn, user]);

  const toggleMenu = () => setIsOpen(!isOpen);

  const publicLinks = [{ href: '/', label: 'Home' }];

  const privateLinks = [
    { href: '/record', label: 'Record' },
    { href: '/settings', label: 'Settings' },
  ];

  const linkStyles =
    'text-white hover:bg-green-500 font-medium text-lg transition-colors duration-200 px-3 py-2 rounded-md';

  const renderLinks = (links) =>
    links.map((link) => (
      <Link key={link.href} href={link.href} className={linkStyles}>
        {link.label}
      </Link>
    ));

  return (
    <nav className='bg-green-600 shadow-md'>
      <div className='max-w-7xl mx-auto px-4'>
        <div className='flex items-center justify-between h-16'>
          <Link className='flex items-center space-x-3' href='/'>
            <Image
              src='/logo.png'
              alt='Your site Logo'
              width={40}
              height={40}
              quality={100}
              priority
            />
            <span className='text-2xl font-bold text-white hidden sm:inline'>
              talkTranslate
            </span>
          </Link>

          <div className='hidden md:flex md:items-center md:space-x-4'>
            {renderLinks(publicLinks)}
            <SignedIn>{renderLinks(privateLinks)}</SignedIn>
            <SignedOut>
              <SignInButton mode='modal'>
                <button className={linkStyles}>Sign In</button>
              </SignInButton>
            </SignedOut>
            <SignedIn>
              <UserButton
                appearance={{
                  elements: {
                    userButtonAvatarBox: 'w-10 h-10',
                  },
                }}
              />
            </SignedIn>
          </div>

          <div className='md:hidden'>
            <button
              onClick={toggleMenu}
              className='inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
            >
              <span className='sr-only'>Open main menu</span>
              {isOpen ? (
                <X className='block h-6 w-6' aria-hidden='true' />
              ) : (
                <Menu className='block h-6 w-6' aria-hidden='true' />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className='md:hidden'>
          <div className='px-2 pt-2 pb-3 flex flex-wrap items-center justify-between'>
            <div className='flex flex-wrap items-center space-x-1'>
              {renderLinks(publicLinks)}
              <SignedIn>{renderLinks(privateLinks)}</SignedIn>
            </div>
            <div className='mt-2 md:mt-0'>
              <SignedOut>
                <SignInButton mode='modal'>
                  <button className={`${linkStyles} text-sm px-3 py-2`}>
                    Sign In
                  </button>
                </SignInButton>
              </SignedOut>
              <SignedIn>
                <UserButton
                  appearance={{
                    elements: {
                      userButtonAvatarBox: 'w-10 h-10',
                    },
                  }}
                />
              </SignedIn>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}

function LayoutContent({ children }) {
  const { isLoaded, isSignedIn } = useUser();

  if (!isLoaded) {
    return <div className='flex flex-col justify-center items-center h-screen bg-gray-100'>
    <div className='loader animate-spin h-12 w-12 border-4 border-t-4 border-green-600 rounded-full mb-4'></div>
    <p className='text-xl text-green-800 font-semibold'>
      Loading...
    </p>
  </div>;
  }

  return (
    <>
      <NavBar />
      {isSignedIn ? (
        <InitializeSettings>{children}</InitializeSettings>
      ) : (
        children
      )}
    </>
  );
}

export default function RootLayout({ children }) {
  return (
    <ClerkProvider>
      <html lang='en'>
        <body>
          <LayoutContent>{children}</LayoutContent>
        </body>
      </html>
    </ClerkProvider>
  );
}
